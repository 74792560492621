import React, { useEffect, useState } from "react";
import axios from 'axios';
import carData from "../assets/data/carData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ListViewItem from "../components/UI/ListViewItem";
import FindCarForm from "../components/UI/FindCarForm";

const CarDetails = () => {
  const { slug } = useParams();
  const [vehicleData, setVehicleData] = useState([]);
  const [relatedVehicleData, setRelatedVehicleData] = useState([]);
  const [vehicleServices, setVehicleServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate(); // Replace useHistory with useNavigate


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [formData, setFormData] = useState({
    pick_up_location: '',
    return_location: '',
    pick_up_time_date: '',
    return_time_date: '',
    person_title: '',
    first_name: '',
    last_name: '',
    vehicle_type: '',
    contact_number: '',
    email: '',
    agent: '',
    services: {},
  });

  const singleCarItem = carData.find((item) => item.carName === slug);

  useEffect(() => {
    console.log(singleCarItem);
    window.scrollTo(0, 0);
  }, [singleCarItem]);

  useEffect(() => {
    const fetchCar = async () => {
      try {
        const response = await axios.get(`https://admin.rentacarsrilankahbs.com/api/product/details?id=${slug}`);
        console.log(response.data);
        setVehicleData(response.data);
        setName(response.data.name);
        setDescription(response.data.description);
        setImage(response.data.image_path);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching car details', error);
        setLoading(false);
      }
    };
    fetchCar();
  }, [slug]);

  useEffect(() => {
    let isMounted = true; // Note this flag denotes mount status

    axios.get('https://admin.rentacarsrilankahbs.com/api/product/allProduts')
      .then(res => {
        if (isMounted) {
          // setCars(res.data);
          console.log(res.data);
          setRelatedVehicleData(res.data)
        }
      })
      .catch(error => {
        console.error("There was an error fetching the cars!", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true; // Note this flag denotes mount status

    axios.get('https://admin.rentacarsrilankahbs.com/api/vehicle/services')
      .then(res => {
        if (isMounted) {
          // setCars(res.data);
          console.log(res.data);
          setVehicleServices(res.data)
        }
      })
      .catch(error => {
        console.error("There was an error fetching the cars!", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const SamplePrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block", left: "40px", zIndex: 1, fontSize: "50px" }}
      onClick={onClick}
    >
      {/* <FaArrowLeft style={{ color: "#000", fontSize: "20px" }} /> */}
    </div>
  );

  const SampleNextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block", right: "40px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* <FaArrowRight style={{ color: "#000", fontSize: "20px" }} /> */}
    </div>
  );

  const sliderSettings = {
    dots: false,
    infinite: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleServiceChange = (serviceName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      services: {
        ...prevState.services,
        [serviceName]: value
      }
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsSuccess(false);
    setIsError(false);

    try {
      const response = await fetch('https://formspree.io/f/movarzqz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSuccess(true);
        setFormData({
          pick_up_location: '',
          return_location: '',
          pick_up_time_date: '',
          return_time_date: '',
          vehicle_type: '',
          contact_number: '',
          email: '',
        });
        window.alert('Email sent successfully!');
        window.location.reload();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showFormData = () => {
    console.log(formData);
  }

  return (
    <Helmet title={name}>
      <section>
        <Container>
          <Row>
            <Col lg="4">
              <Slider {...sliderSettings}>
                {vehicleData.product_image?.map((imagedata, index) => (
                  <div key={index}>
                    <img
                      src={`https://admin.rentacarsrilankahbs.com/${imagedata.image}`}
                      alt={`Slide ${index}`}
                      className="w-100"
                    />
                  </div>
                ))}
              </Slider>

              {/* Add the image-like section here */}
              <div className="image-like-section mt-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Per Day Rate</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleData.one_week_per_day_rate || vehicleData.one_week_per_total ?
                      <tr>
                        <th>For 1 week</th>
                        <td>{vehicleData.one_week_per_day_rate ? parseFloat(vehicleData.one_week_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.one_week_per_total ? parseFloat(vehicleData.one_week_per_total).toFixed(2) : ""}/=</td>
                      </tr>
                      : ''
                    }

                    {vehicleData.two_week_per_day_rate || vehicleData.two_week_per_total ?
                      <tr>
                        <th>For 2 week</th>
                        <td>{vehicleData.two_week_per_day_rate ? parseFloat(vehicleData.two_week_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.two_week_per_total ? parseFloat(vehicleData.two_week_per_total).toFixed(2) : ""}/=</td>
                      </tr>
                      : ''
                    }

                    {vehicleData.three_week_per_day_rate || vehicleData.three_week_per_total ?
                      <tr>
                        <th>For 3 week</th>
                        <td>{vehicleData.three_week_per_day_rate ? parseFloat(vehicleData.three_week_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.three_week_per_total ? parseFloat(vehicleData.three_week_per_total).toFixed(2) : ""}/=</td>
                      </tr>
                      : ''
                    }

                    {vehicleData.one_month_per_day_rate || vehicleData.one_months_per_total ?
                      <tr>
                        <th>For 1 month</th>
                        <td>{vehicleData.one_month_per_day_rate ? parseFloat(vehicleData.one_month_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.one_months_per_total ? parseFloat(vehicleData.one_months_per_total).toFixed(2) : ""}/= 
                        <br/><small>Per Month Rate</small>
                        </td>
                      </tr>
                      : ''
                    }

                    {vehicleData.three_months_per_day_rate || vehicleData.three_months_per_total ?
                      <tr>
                        <th>For 3 month</th>
                        <td>{vehicleData.three_months_per_day_rate ? parseFloat(vehicleData.three_months_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.three_months_per_total ? parseFloat(vehicleData.three_months_per_total).toFixed(2) : ""}/=
                        <br/><small>Per Month Rate</small>
                        </td>
                      </tr>
                      : ''
                    }


                    {vehicleData.six_months_per_day_rate || vehicleData.six_months_per_total ?
                      <tr>
                        <th>For 6 months & Over</th>
                        <td>{vehicleData.six_months_per_day_rate ? parseFloat(vehicleData.six_months_per_day_rate).toFixed(2) : ""}/=</td>
                        <td>{vehicleData.six_months_per_total ? parseFloat(vehicleData.six_months_per_total).toFixed(2) : ""}/= 
                        <br/><small>Per Month Rate</small>
                        </td>
                      </tr>
                      : ''
                    }
                  </tbody>
                </table>

                <div className="discount-note mt-3 discount_blink">
                  Up to 15% off (Conditions apply)
                </div>

                <div className="discount-note mt-3">
                  Above rentals will be higher for foreigners with extra commitments.
                </div>

                <div className="discount-note mt-3 text-center">
                  <a href="tel:+94777425008">TEL - +94 777425008</a> 
                </div>
                <div className="discount-note mt-3 text-center">
                  <a href="tel:+94777435008">TEL - +94 777435008</a>
                </div>


                <div className="notice">

                  <div className="discount-note mt-3">
                    <h4>Notice</h4>
                  </div>
                  <ul className="notice-list">

                    <li>➔ Standard rates are only for 100km</li>
                    <li>➔ These rates are not valid for foreigners.</li>
                    <li>
                      ➔ Above-given rates can be changed (increase or decrease)
                      from customer to customer due to high demand especially
                      in seasons, availability, features, grade, model year,
                      mileage usage, risk assessment etc.
                    </li>
                  </ul>
                  <p className="additional-note">
                    We maintain floating rates in sessional months so above rates
                    can be changed accordingly.
                  </p>
                </div>

                <div className="fleet-specifications">
                  <div className="discount-note mt-3">
                    <h4>Specifications</h4>
                  </div>
                  <ul className="specifications-list">
                    {vehicleData.ac ? vehicleData.ac == 1 ? <li>❯ Air conditioning</li> : '' : ''}
                    {vehicleData.automatic_shift ? vehicleData.automatic_shift == 1 ? <li>❯ Automatic Shift</li> : '' : ''}
                    {vehicleData.doors ? vehicleData.doors != null ? <li>❯ {vehicleData.doors} Doors</li> : '' : null}
                    {vehicleData.seats ? vehicleData.seats != null ? <li>❯ {vehicleData.seats} Seats</li> : '' : null}
                    {/* {vehicleData.suitcase ? vehicleData.suitcase != null ? ❯ {vehicleData.suitcase} Suitcase</li> : '' : null} */}
                    {vehicleData.air_baloons ? vehicleData.air_baloons == 1 ? <li>❯ Air Balloons</li> : '' : ''}
                    {vehicleData.size ? vehicleData.size != null ? <li>❯  Fuel type: {vehicleData.size}</li> : '' : null}

                    {/* <li>❯ Air conditioning</li> */}

                    {/* <li>❯ 4 Doors</li> */}
                    {/* <li>❯ 5 Seats</li> */}
                    {/* <li>❯ 4 Suitcase & 6 Bags</li> */}

                    {/* <li>❯ Fuel type: Diesel</li> */}
                  </ul>
                </div>

                <div className="discount-note mt-3">
                  <h4>Related Fleets</h4>
                </div>

                <div className="related-items">
                  {relatedVehicleData.map((item) =>
                    vehicleData.id !== item.id ? <ListViewItem item={item} key={item.id} /> : null
                  )}
                </div>




              </div>
            </Col>

            <Col lg="8">
              <div className="car__info">
                <h2 className="section__title">{name}</h2>
                {/* <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    Rs {vehicleData.current_sale_price ? vehicleData.current_sale_price : ''} / Day
                  </h6>
                </div> */}
                <p className="section__description">
                  {description}
                </p>
                <div className=" d-flex align-items-center mt-3" style={{ columnGap: "4rem" }}>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-roadster-line" style={{ color: "#D6222A" }}></i>
                    {name}
                  </span>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-settings-2-line" style={{ color: "#D6222A" }}></i>
                    {vehicleData.discount_type == 1 ? "Automatic" : "Manual"}
                  </span>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-timer-flash-line" style={{ color: "#D6222A" }}></i>
                    {vehicleData.wholesale_minimum_qty}kmpl
                  </span>
                </div>
              </div>


              <div className="single-item-form-section ">
                <h3 className="fw-600" style={{ color: "#FFFFFF" }}>Reservation</h3>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Pick up Location"
                          name="pick_up_location"
                          value={formData.pick_up_location}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Return Location"
                          name="return_location"
                          value={formData.return_location}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label htmlFor="pick_up_time_date" style={{ color: "#FFFFFF" }}>Pick up Date & Time</label>
                        <input
                          type="datetime-local"
                          placeholder="Pick up Date & Time"
                          name="pick_up_time_date"
                          value={formData.pick_up_time_date}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label htmlFor="return_time_date" style={{ color: "#FFFFFF" }}>Return Date & Time</label>
                        <input
                          type="datetime-local"
                          placeholder="Return up Date & Time"
                          name="return_time_date"
                          value={formData.return_time_date}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <select
                          name="person_title"
                          value={formData.person_title}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Title</option>
                          <option value="car">Mr</option>
                          <option value="van">Ms</option>
                          <option value="bus">Dr</option>
                          <option value="bus">Other</option>
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Contact Number"
                          name="contact_number"
                          value={formData.contact_number}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <select
                          name="agent"
                          value={formData.agent}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Contacted Agent (optional)</option>
                          <option value="car">Office 1</option>
                          <option value="car">Office 2</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <h5 className=" mt-2" style={{ color: "#FFFFFF" }}>Recommended Extra Services</h5>
                  <Row className="item_services_section">
                    {vehicleServices.map((service) => (
                      <Col sm={5} key={service.id} className="item_services_section_blue_bg">
                        <h5>{service.service_name}</h5>
                        <label className="ms-2 me-2">
                          <input
                            type="radio"
                            name={service.service_name}
                            value="yes"
                            checked={formData.services[service.service_name] === 'yes'}
                            onChange={(e) => handleServiceChange(service.service_name, e.target.value)}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={service.service_name}
                            value="no"
                            checked={formData.services[service.service_name] === 'no'}
                            onChange={(e) => handleServiceChange(service.service_name, e.target.value)}
                          />
                          No
                        </label>
                      </Col>
                    ))}
                  </Row>


                  <button type="submit" className="btn btn-primary single-item-form-btn" disabled={isSubmitting} style={{ width: "100%", border: "solid 2px #FFFFFF !important" }}>
                    {isSubmitting ? 'Sending...' : 'Booking'}
                  </button>
                  {isSuccess && <p>Email sent successfully!</p>}
                  {isError && <p>Failed to send email. Please try again.</p>}
                </form>
              </div>

              {/* <button onClick={showFormData}>check data</button>

              <div className="item_services_section">
                <h2 className="section__title mt-2">Recommended Extra Services</h2>

                <Row>
                  {vehicleServices.map((service, index) => (
                    <Col sm={5} key={service.id} className="item_services_section_blue_bg">
                      <h5>{service.service_name}</h5>
                      <label className="ms-2 me-2">
                        <input type="radio" name={service.service_name} value="yes" />
                        Yes
                      </label>
                      <label>
                        <input type="radio" name={service.service_name} value="no" />
                        No
                      </label>
                    </Col>
                  ))}
                </Row>


              </div>

              <div>
                <button className="btn btn-primary">CONTINUE</button>
              </div> */}
            </Col>

            <Col lg="7" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold "></h5>
              </div>
            </Col>

            <Col lg="5" className="mt-5">
              {/* <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
