import React, { useState } from 'react';
import '../../styles/find-car-form.css';

const FindCarForm = () => {
  const [formData, setFormData] = useState({
    pick_up_location: '',
    return_location: '',
    pick_up_time_date: '',
    return_time_date: '',
    vehicle_type: '',
    contact_number: '',
    email: '',
    way_type: '', // Default value

  });

  const [activeTab, setActiveTab] = useState('wedding');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsSuccess(false);
    setIsError(false);

    try {
      const response = await fetch('https://formspree.io/f/movarzqz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSuccess(true);
        setFormData({
          pick_up_location: '',
          return_location: '',
          pick_up_time_date: '',
          return_time_date: '',
          vehicle_type: '',
          contact_number: '',
          email: '',
        });
        window.alert('Email sent successfully!');
        window.location.reload();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleServiceChange = (serviceName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [serviceName]: value
    }));
  };


  const renderContent = () => {
    switch (activeTab) {
      case 'self_drive':
        return (
          <div className="tab-content">
            <h3 className="fw-600">SELF DRIVE</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Pick up Location"
                  name="pick_up_location"
                  value={formData.pick_up_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Return Location"
                  name="return_location"
                  value={formData.return_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Pick up Date & Time"
                  name="pick_up_time_date"
                  value={formData.pick_up_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Return Date & Time"
                  name="return_time_date"
                  value={formData.return_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  name="vehicle_type"
                  value={formData.vehicle_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="car">Car</option>
                  <option value="van">Van</option>
                  <option value="bus">Bus</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Booking'}
              </button>
              {isSuccess && <p>Email sent successfully!</p>}
              {isError && <p>Failed to send email. Please try again.</p>}
            </form>
          </div>
        );
      case 'with_drive':
        return (
          <div className="tab-content">
            <h3 className="fw-600">WITH DRIVER</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Pick up Location"
                  name="pick_up_location"
                  value={formData.pick_up_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Return Location"
                  name="return_location"
                  value={formData.return_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Pick up Date & Time"
                  name="pick_up_time_date"
                  value={formData.pick_up_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Return Date & Time"
                  name="return_time_date"
                  value={formData.return_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  name="vehicle_type"
                  value={formData.vehicle_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="car">Car</option>
                  <option value="van">Van</option>
                  <option value="bus">Bus</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Search'}
              </button>
              {isSuccess && <p>Email sent successfully!</p>}
              {isError && <p>Failed to send email. Please try again.</p>}
            </form>
          </div>
        );
      case 'airport':
        return (
          <div className="tab-content">
            <h3 className="fw-600">AIRPORT TRANSFER</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Pick up Location"
                  name="pick_up_location"
                  value={formData.pick_up_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Return Location"
                  name="return_location"
                  value={formData.return_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Pick up Date & Time"
                  name="pick_up_time_date"
                  value={formData.pick_up_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Return Date & Time"
                  name="return_time_date"
                  value={formData.return_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  name="vehicle_type"
                  value={formData.vehicle_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="car">Car</option>
                  <option value="van">Van</option>
                  <option value="bus">Bus</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Search'}
              </button>
              {isSuccess && <p>Email sent successfully!</p>}
              {isError && <p>Failed to send email. Please try again.</p>}
            </form>
          </div>
        );
      case 'wedding_events':
        return (
          <div className="tab-content">
            <h3 className="fw-600">WEDDING & EVENTS <br></br>
              <input
                className="ms-2 me-2"
                type="radio"
                name="way_type"
                value="one_way"
                checked={formData["way_type"] === 'one_way'}
                onChange={(e) => handleServiceChange("way_type", "one_way")}
                style={{ fontSize: "22px" }}

              /> <span style={{ color: "#ffffff", fontSize: "18px" }}>One Way</span>

              <input
                className="ms-2 me-2"
                type="radio"
                name="way_type"
                value="both_way"
                checked={formData["way_type"] === 'both_way'}
                onChange={(e) => handleServiceChange("way_type", "both_way")}
              /> <span style={{ color: "#ffffff", fontSize: "18px" }}>Both Way</span>
            </h3>
            <form onSubmit={handleSubmit}>


              <div className="form-group">
                <input
                  type="text"
                  placeholder="Pick up Location"
                  name="pick_up_location"
                  value={formData.pick_up_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Return Location"
                  name="return_location"
                  value={formData.return_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Pick up Date & Time"
                  name="pick_up_time_date"
                  value={formData.pick_up_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Return Date & Time"
                  name="return_time_date"
                  value={formData.return_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  name="vehicle_type"
                  value={formData.vehicle_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="car">Car</option>
                  <option value="van">Van</option>
                  <option value="bus">Bus</option>
                </select>
              </div>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Search'}
              </button>
              {isSuccess && <p>Email sent successfully!</p>}
              {isError && <p>Failed to send email. Please try again.</p>}
            </form>
          </div>
        );
      case 'limousine':
        return (
          <div className="tab-content">
            <h3 className="fw-600">LIMOUSINE SERVICES</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Pick up Location"
                  name="pick_up_location"
                  value={formData.pick_up_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Return Location"
                  name="return_location"
                  value={formData.return_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Pick up Date & Time"
                  name="pick_up_time_date"
                  value={formData.pick_up_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Return Date & Time"
                  name="return_time_date"
                  value={formData.return_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  name="vehicle_type"
                  value={formData.vehicle_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="car">Car</option>
                  <option value="van">Van</option>
                  <option value="bus">Bus</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Search'}
              </button>
              {isSuccess && <p>Email sent successfully!</p>}
              {isError && <p>Failed to send email. Please try again.</p>}
            </form>
          </div>
        );
      default:
        return (
          <div className="tab-content">
            <h3 className="fw-600">SELF DRIVE</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Pick up Location"
                  name="pick_up_location"
                  value={formData.pick_up_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Return Location"
                  name="return_location"
                  value={formData.return_location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Pick up Date & Time"
                  name="pick_up_time_date"
                  value={formData.pick_up_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="datetime-local"
                  placeholder="Return Date & Time"
                  name="return_time_date"
                  value={formData.return_time_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  name="vehicle_type"
                  value={formData.vehicle_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="car">Car</option>
                  <option value="van">Van</option>
                  <option value="bus">Bus</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Search'}
              </button>
              {isSuccess && <p>Email sent successfully!</p>}
              {isError && <p>Failed to send email. Please try again.</p>}
            </form>
          </div>
        );
    }
  };

  return (
    <div className="find-car-form">
      <div className="tabs">
        <button
          className={`tablinks ${activeTab === 'self_drive' ? 'active' : ''}`}
          onClick={() => setActiveTab('self_drive')}
        >
          <i className="ri-user-6-fill"></i>
        </button>

        <button
          className={`tablinks ${activeTab === 'with_drive' ? 'active' : ''}`}
          onClick={() => setActiveTab('with_drive')}
        >
          <i className="ri-user-add-fill"></i>
        </button>

        <button
          className={`tablinks ${activeTab === 'airport' ? 'active' : ''}`}
          onClick={() => setActiveTab('airport')}
        >
          <i className="ri-plane-line"></i>
        </button>

        <button
          className={`tablinks ${activeTab === 'wedding_events' ? 'active' : ''}`}
          onClick={() => setActiveTab('wedding_events')}
        >
          <i className="ri-user-heart-fill"></i>
        </button>

        <button
          className={`tablinks ${activeTab === 'limousine' ? 'active' : ''}`}
          onClick={() => setActiveTab('limousine')}
        >
          <i className="ri-taxi-wifi-fill"></i>
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default FindCarForm;
