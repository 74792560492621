import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import Logo from "../../assets/all-images/logo/logo.png";

const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  // {
  //   path: "#",
  //   display: "Privacy Policy",
  // },

  // {
  //   path: "/cars",
  //   display: "Car Listing",
  // },
  {
    path: "/blogs",
    display: "Blog",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <img src={Logo} alt="" className="w-100" />
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              HBS Rent A Car began with a simple vision: to revolutionize the car rental experience by putting our customers first. From our humble beginnings, we have grown into a renowned name, known for our diverse fleet, competitive pricing, and unwavering dedication to quality.            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">Dambulla</p>
              <p className="office__info">Phone: <a href ="tel:+94777425008" className="text-white">+94 777425008</a> / <a href ="tel:++94777425008" className="text-white">+94 777425008</a></p>
 
              <p className="office__info">Email: info@rentacarsrilankahbs.com</p>

              <p className="office__info">Office Time: 7am - 6pm</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              {/* <p className="section__description">Subscribe our newsletter</p> */}
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          {/* <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Developed by
                Muhibur Rahman. All rights reserved.
              </p>
            </div>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
